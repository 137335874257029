import { elastic, searchQuery } from '@graphql'
import { useWindowSize } from '@hooks'
import { useLocation } from '@reach/router'
import { ResponseType, ElasticCatalogItem } from '@types'
import { navigate } from 'gatsby'
import { debounce, isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

interface UseGolfClubModelSearchConfig {
  withoutUrlSearchParams?: boolean
}

const useGolfClubModelSearch = (config?: UseGolfClubModelSearchConfig) => {
  const { toRender: isMobile } = useWindowSize(['mobile', 'landscape'])
  const { search: params, pathname } = useLocation()
  const [search, setSearch] = useState<string | undefined>()
  const urlSearchParams = new URLSearchParams(params)
  const initialSearch = urlSearchParams.get('term') || ''

  const [createSearchQuery] = useMutation(searchQuery.createSearchQuery)

  useEffect(() => {
    setSearch(initialSearch)
  }, [])

  const { data, loading, refetch } = useQuery<ResponseType<ElasticCatalogItem[]>, { term?: string; limit?: number }>(
    elastic.ElasticCatalogItems,
    {
      skip: isEmpty(search),
      notifyOnNetworkStatusChange: true,
      variables: { limit: 6, term: '' }
    }
  )

  const openCataloguePageWithSearch = async () => {
    const isCataloguePage = pathname.includes('catalogue')
    urlSearchParams.delete('optionIds')
    if (!isCataloguePage) {
      await navigate('/catalogue' + '?' + urlSearchParams.toString())
      return
    }
    if (isMobile) {
      urlSearchParams.delete('categoryIds')
      urlSearchParams.set('mobileTerm', search || '')
      await navigate('/catalogue' + '?' + urlSearchParams.toString())
    }
  }

  const handleUrlSearchQueryClear = async () => {
    setSearch('')
    if (!withoutUrlSearchParams) {
      urlSearchParams.delete('term')
      await refetch({ term: '' })
      navigate(decodeURIComponent('?' + urlSearchParams.toString()))
    }
  }

  const debouncedFetch = useCallback(
    debounce(async (search: string) => {
      await refetch({ term: search }).then(() => {
        setTimeout(() => {
          if (search?.length && search !== '') createSearchQuery({ variables: { searchQuery: { term: search } } })
        }, 3000)
      })
    }, 300),
    []
  )

  const handleSearch = (search: string) => {
    setSearch(search)
    debouncedFetch(search)
    if (!withoutUrlSearchParams) {
      urlSearchParams.set('term', encodeURIComponent(search))
      navigate('?' + urlSearchParams.toString())
    }
  }

  const withoutUrlSearchParams = config?.withoutUrlSearchParams
  const catalogItems = data?.res || []

  return {
    handleSearch,
    handleUrlSearchQueryClear,
    search: decodeURIComponent(search || ''),
    setSearch,
    catalogItems,
    loading,
    initialSearch,
    openCataloguePageWithSearch,
    params
  }
}

export default useGolfClubModelSearch
