// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-dev-404-page-js": () => import("./../../dev-404-page.js" /* webpackChunkName: "component---cache-dev-404-page-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-guides-index-tsx": () => import("./../../../src/pages/about/guides/index.tsx" /* webpackChunkName: "component---src-pages-about-guides-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-account-browsing-history-index-tsx": () => import("./../../../src/pages/account/browsing-history/index.tsx" /* webpackChunkName: "component---src-pages-account-browsing-history-index-tsx" */),
  "component---src-pages-account-locker-index-tsx": () => import("./../../../src/pages/account/locker/index.tsx" /* webpackChunkName: "component---src-pages-account-locker-index-tsx" */),
  "component---src-pages-account-my-offer-book-index-tsx": () => import("./../../../src/pages/account/my-offer-book/index.tsx" /* webpackChunkName: "component---src-pages-account-my-offer-book-index-tsx" */),
  "component---src-pages-account-my-offer-book-purchase-request-id-details-index-tsx": () => import("./../../../src/pages/account/my-offer-book/[purchaseRequestId]/details/index.tsx" /* webpackChunkName: "component---src-pages-account-my-offer-book-purchase-request-id-details-index-tsx" */),
  "component---src-pages-account-my-offer-book-purchase-request-id-edit-details-tsx": () => import("./../../../src/pages/account/my-offer-book/[purchaseRequestId]/edit-details.tsx" /* webpackChunkName: "component---src-pages-account-my-offer-book-purchase-request-id-edit-details-tsx" */),
  "component---src-pages-account-order-history-[id]-details-tsx": () => import("./../../../src/pages/account/order-history/[id]/details.tsx" /* webpackChunkName: "component---src-pages-account-order-history-[id]-details-tsx" */),
  "component---src-pages-account-order-history-index-tsx": () => import("./../../../src/pages/account/order-history/index.tsx" /* webpackChunkName: "component---src-pages-account-order-history-index-tsx" */),
  "component---src-pages-account-place-a-club-index-tsx": () => import("./../../../src/pages/account/place-a-club/index.tsx" /* webpackChunkName: "component---src-pages-account-place-a-club-index-tsx" */),
  "component---src-pages-account-profile-index-tsx": () => import("./../../../src/pages/account/profile/index.tsx" /* webpackChunkName: "component---src-pages-account-profile-index-tsx" */),
  "component---src-pages-account-store-index-tsx": () => import("./../../../src/pages/account/store/index.tsx" /* webpackChunkName: "component---src-pages-account-store-index-tsx" */),
  "component---src-pages-account-store-my-golf-club-inventory-golf-item-id-details-index-tsx": () => import("./../../../src/pages/account/store/my-golf-club-inventory/[golfItemId]/details/index.tsx" /* webpackChunkName: "component---src-pages-account-store-my-golf-club-inventory-golf-item-id-details-index-tsx" */),
  "component---src-pages-account-store-my-golf-club-inventory-golf-item-id-edit-details-tsx": () => import("./../../../src/pages/account/store/my-golf-club-inventory/[golfItemId]/edit-details.tsx" /* webpackChunkName: "component---src-pages-account-store-my-golf-club-inventory-golf-item-id-edit-details-tsx" */),
  "component---src-pages-account-store-my-golf-club-inventory-index-tsx": () => import("./../../../src/pages/account/store/my-golf-club-inventory/index.tsx" /* webpackChunkName: "component---src-pages-account-store-my-golf-club-inventory-index-tsx" */),
  "component---src-pages-account-store-my-sales-history-index-tsx": () => import("./../../../src/pages/account/store/my-sales-history/index.tsx" /* webpackChunkName: "component---src-pages-account-store-my-sales-history-index-tsx" */),
  "component---src-pages-account-store-my-sales-history-order-id-details-tsx": () => import("./../../../src/pages/account/store/my-sales-history/[orderId]/details.tsx" /* webpackChunkName: "component---src-pages-account-store-my-sales-history-order-id-details-tsx" */),
  "component---src-pages-account-store-start-selling-index-tsx": () => import("./../../../src/pages/account/store/start-selling/index.tsx" /* webpackChunkName: "component---src-pages-account-store-start-selling-index-tsx" */),
  "component---src-pages-authenticate-index-tsx": () => import("./../../../src/pages/authenticate/index.tsx" /* webpackChunkName: "component---src-pages-authenticate-index-tsx" */),
  "component---src-pages-browse-[slug]-index-tsx": () => import("./../../../src/pages/browse/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-browse-[slug]-index-tsx" */),
  "component---src-pages-catalogue-index-tsx": () => import("./../../../src/pages/catalogue/index.tsx" /* webpackChunkName: "component---src-pages-catalogue-index-tsx" */),
  "component---src-pages-checkout-[type]-[id]-buy-now-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/buy-now.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-buy-now-tsx" */),
  "component---src-pages-checkout-[type]-[id]-payment-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/payment.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-payment-tsx" */),
  "component---src-pages-checkout-[type]-[id]-review-buy-price-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/review-buy-price.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-review-buy-price-tsx" */),
  "component---src-pages-checkout-[type]-[id]-sell-now-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/sell-now.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-sell-now-tsx" */),
  "component---src-pages-checkout-[type]-[id]-set-buy-price-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/set-buy-price.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-set-buy-price-tsx" */),
  "component---src-pages-checkout-[type]-[id]-set-sell-price-images-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/set-sell-price-images.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-set-sell-price-images-tsx" */),
  "component---src-pages-checkout-[type]-[id]-set-sell-price-tsx": () => import("./../../../src/pages/checkout/[type]/[id]/set-sell-price.tsx" /* webpackChunkName: "component---src-pages-checkout-[type]-[id]-set-sell-price-tsx" */),
  "component---src-pages-checkout-success-index-tsx": () => import("./../../../src/pages/checkout-success/index.tsx" /* webpackChunkName: "component---src-pages-checkout-success-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/FAQ/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-our-policy-payment-index-tsx": () => import("./../../../src/pages/our-policy/payment/index.tsx" /* webpackChunkName: "component---src-pages-our-policy-payment-index-tsx" */),
  "component---src-pages-our-policy-privacy-index-tsx": () => import("./../../../src/pages/our-policy/privacy/index.tsx" /* webpackChunkName: "component---src-pages-our-policy-privacy-index-tsx" */),
  "component---src-pages-our-policy-return-refund-index-tsx": () => import("./../../../src/pages/our-policy/return-refund/index.tsx" /* webpackChunkName: "component---src-pages-our-policy-return-refund-index-tsx" */),
  "component---src-pages-our-policy-shipping-index-tsx": () => import("./../../../src/pages/our-policy/shipping/index.tsx" /* webpackChunkName: "component---src-pages-our-policy-shipping-index-tsx" */),
  "component---src-pages-product-[slug]-index-tsx": () => import("./../../../src/pages/product/[slug]/index.tsx" /* webpackChunkName: "component---src-pages-product-[slug]-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/TERMS/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-typography-tsx": () => import("./../../../src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */)
}

